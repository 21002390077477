import { lazy } from 'react';
import {
	accountings,
	allVehicle,
	auctions,
	bidAuction,
	bill,
	customer_queries,
	customers,
	dashboardMenu,
	demoPages,
	faqs,
	invoice,
	jobs,
	layoutMenu,
	productService,
	profiles,
	settings,
	vehiclePages,
	vehicles,
} from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const VEHICLE_SETTINGS = {
	MAKE: lazy(() => import('../pages/settings/vehicles/make/Make')),
	MODEL: lazy(() => import('../pages/settings/vehicles/model/Model')),
	BODY_STYLE: lazy(() => import('../pages/settings/vehicles/body-style/BodyStyle')),
	COLOR_STYLE: lazy(() => import('../pages/settings/vehicles/color-style/ColorStyle')),
	VEHICLE_DAMAGE: lazy(() => import('../pages/settings/vehicles/vehicle-damage/VehicleDamage')),
};

const VEHICLES = {
	VEHICLE: lazy(() => import('../pages/vehicles/Vehicles')),
	SOLDVEHICLE: lazy(() => import('../pages/vehicles/SoldVehicle')),
	SELLAPPVEHICLE: lazy(() => import('../pages/vehicles/SellAppVehicle')),
	RETURNEDVEHICLE: lazy(() => import('../pages/vehicles/ReturnedVehicles')),
	SHOWVEHICLE: lazy(() => import('../pages/vehicles/ShowVehicle')),
};

const NOTIFICATIONS = {
	NOTIFICATION: lazy(() => import('../pages/notifications/Notifications')),
};

const CUSTOMERS = {
	SELLER: lazy(() => import('../pages/customers/sellers/Sellers')),
	SHOWSELLER: lazy(() => import('../pages/customers/sellers/ShowSeller')),
	MEMBER: lazy(() => import('../pages/customers/members/Members')),
	NEWMEMBER: lazy(() => import('../pages/customers/newMembers/NewMembers')),
	SHOWMEMBER: lazy(() => import('../pages/customers/members/ShowMember')),
};
const SETTINGS = {
	SYSTEM_SETTIGS: lazy(() => import('../pages/settings/systemSettings/SystemSetting')),
	USER: lazy(() => import('../pages/settings/users/User')),
	SHOWUSER: lazy(() => import('../pages/settings/users/ShowUser')),
	USER_ROLE: lazy(() => import('../pages/settings/users/UserPermission')),
	ROLE: lazy(() => import('../pages/settings/roles/Role')),
	ROLE_Manage: lazy(() => import('../pages/settings/roles/UserRoleManage')),
	ZONE: lazy(() => import('../pages/settings/zones/Zone')),
	COUNTRY: lazy(() => import('../pages/settings/countries/Country')),
	STATE: lazy(() => import('../pages/settings/states/State')),
	LOCATION: lazy(() => import('../pages/settings/locations/Location')),
	ACUTION_YARD: lazy(() => import('../pages/settings/acution-yard/AcutionYard')),
	ACUTION_YARD_SHOW: lazy(() => import('../pages/settings/acution-yard/ShowAuctionYard')),
	EMAIL_TEMPLATE: lazy(() => import('../pages/settings/email-template/EmailTemplate')),
	CREATE_EMAIL_TEMPLATE: lazy(
		() => import('../pages/settings/email-template/CreateEmailTemplate'),
	),
	SHOW_EMAIL_TEMPLATE: lazy(() => import('../pages/settings/email-template/ShowEmailTemplate')),
	PAGES: lazy(() => import('../pages/settings/pages/PagesModule')),
	ADD_PAGE: lazy(() => import('../pages/settings/pages/AddPage')),
	SHOW_PAGES: lazy(() => import('../pages/settings/pages/ShowPage')),
	BANNERS: lazy(() => import('../pages/settings/banners/Banner')),
	ABOUTBANNERS: lazy(() => import('../pages/settings/banners/AboutBanner')),
	CONTACTBANNERS: lazy(() => import('../pages/settings/banners/ContactBanner')),
	DOWNLOADBANNERS: lazy(() => import('../pages/settings/banners/DownloadBanner')),
	CAREERBANNERS: lazy(() => import('../pages/settings/banners/CareerBanner')),
	BLOGBANNERS: lazy(() => import('../pages/settings/banners/BlogBanner')),
	POPUPBANNER: lazy(() => import('../pages/settings/banners/PopupBanner')),
	BLOG: lazy(() => import('../pages/settings/blog/Blog')),
	SHOWBLOG: lazy(() => import('../pages/settings/blog/ShowBlog')),
	ADDBLOG: lazy(() => import('../pages/settings/blog/AddBlog')),
	ADDCONTENT: lazy(() => import('../pages/settings/content/AddContent')),
	CONTENT: lazy(() => import('../pages/settings/content/Content')),
	SHOWCONTENT: lazy(() => import('../pages/settings/content/ShowContent')),
	CATALOGS: lazy(() => import('../pages/settings/catalogs/Catalogs')),
	GENERATECATALOGS: lazy(() => import('../pages/settings/catalogs/GenerateCatalogs')),
	DOWNLOADFILES: lazy(() => import('../pages/settings/downloadfile/DFiles')),
	SHOWDF: lazy(() => import('../pages/settings/downloadfile/ShowDF')),
};

const FAQS = {
	FAQ: lazy(() => import('../pages/faqs/Faqs')),
	SHOW_FAQS: lazy(() => import('../pages/faqs/ShowFaq')),
};

const JOBS = {
	JOBS: lazy(() => import('../pages/jobs/Jobs')),
	SHOW_JOBS: lazy(() => import('../pages/jobs/ShowJob')),
};

const CUSTOMER_QUERIES = {
	CUSTOMER_QUERIE: lazy(() => import('../pages/c_query/Query')),
	SHOW_QUERIE: lazy(() => import('../pages/c_query/ShowQueries')),
};
const ACCOUNTINGS = {
	ACCOUNTING: lazy(() => import('../pages/accounting/Accounting')),
	VEHICLESOLD: lazy(() => import('../pages/accounting/VehicleSold/VehicleSold')),
	LATESTSOLD: lazy(() => import('../pages/accounting/LatestSold/LatestVehicleSold')),
};

const INVOICES = {
	INVOICE: lazy(() => import('../pages/invoices/Invoice')),
	SHOWINVOICE: lazy(() => import('../pages/invoices/ShowInvoice')),
};

const BILLS = {
	BILL: lazy(() => import('../pages/bill/Bill')),
	SHOWBILL: lazy(() => import('../pages/bill/ShowBill')),
};

const PRODUCTSERVICE = {
	SERVICE: lazy(() => import('../pages/accounting/ProductService/Service')),
};

const ACUTIONS = {
	ACUTION: lazy(() => import('../pages/auction/Auction')),
	SHOWACUTION: lazy(() => import('../pages/auction/ShowAuction')),
	RUNNINGACUTION: lazy(() => import('../pages/auction/RunningAuction')),
};
const BIDACUTION = {
	BIDACUTION: lazy(() => import('../pages/bidAuction/BidAuction')),
	// SHOWACUTION: lazy(() => import('../pages/auction/ShowAuction')),
};
const PROFILES = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: vehiclePages.singlePages.subMenu.vehicleMake.path,
		element: <VEHICLE_SETTINGS.MAKE />,
		exact: true,
	},
	{
		path: vehiclePages.singlePages.subMenu.vehicleBodyStyle.path,
		element: <VEHICLE_SETTINGS.BODY_STYLE />,
		exact: true,
	},
	{
		path: vehiclePages.singlePages.subMenu.vehicleColorStyle.path,
		element: <VEHICLE_SETTINGS.COLOR_STYLE />,
		exact: true,
	},
	{
		path: vehiclePages.singlePages.subMenu.vehicleDamage.path,
		element: <VEHICLE_SETTINGS.VEHICLE_DAMAGE />,
		exact: true,
	},
	{
		path: customers.singlePages.subMenu.seller.path,
		element: <CUSTOMERS.SELLER />,
		exact: true,
	},
	{
		path: 'customer-settings/sellers/:id',
		element: <CUSTOMERS.SHOWSELLER />,
		exact: true,
	},
	{
		path: customers.singlePages.subMenu.member.path,
		element: <CUSTOMERS.MEMBER />,
		exact: true,
	},
	{
		path: customers.singlePages.subMenu.newMember.path,
		element: <CUSTOMERS.NEWMEMBER />,
		exact: true,
	},
	{
		path: '/customer-settings/members/:id',
		element: <CUSTOMERS.SHOWMEMBER />,
		exact: true,
	},
	{
		path: vehicles.singlePages.path,
		element: <VEHICLES.VEHICLE />,
		exact: true,
	},
	{
		path: allVehicle.singlePages.subMenu.sold_vehicle.path,
		element: <VEHICLES.SOLDVEHICLE />,
		exact: true,
	},
	{
		path: allVehicle.singlePages.subMenu.inventory.path,
		element: <VEHICLES.VEHICLE />,
		exact: true,
	},
	{
		path: allVehicle.singlePages.subMenu.auction_vehicle.path,
		element: <VEHICLES.VEHICLE />,
		exact: true,
	},
	{
		path: 'relist-vehicles',
		element: <VEHICLES.VEHICLE />,
		exact: true,
	},
	{
		path: allVehicle.singlePages.subMenu.on_approval.path,
		element: <VEHICLES.VEHICLE />,
		exact: true,
	},
	{
		path: allVehicle.singlePages.subMenu.documents_validation_pending.path,
		element: <VEHICLES.VEHICLE />,
		exact: true,
	},
	{
		path: allVehicle.singlePages.subMenu.selling_on_approval.path,
		element: <VEHICLES.SELLAPPVEHICLE />,
		exact: true,
	},
	{
		path: allVehicle.singlePages.subMenu.returned_vehicles.path,
		element: <VEHICLES.RETURNEDVEHICLE />,
		exact: true,
	},
	{
		path: 'vehicles/:id',
		element: <VEHICLES.SHOWVEHICLE />,
		exact: true,
	},
	{
		path: vehiclePages.singlePages.subMenu.vehicleModel.path,
		element: <VEHICLE_SETTINGS.MODEL />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.systemSettings.path,
		element: <SETTINGS.SYSTEM_SETTIGS />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.user.path,
		element: <SETTINGS.USER />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.email_template.path,
		element: <SETTINGS.EMAIL_TEMPLATE />,
		exact: true,
	},
	{
		path: '/settings/create-email-notification',
		element: <SETTINGS.CREATE_EMAIL_TEMPLATE />,
		exact: true,
	},
	{
		path: 'settings/email-notification/:id',
		element: <SETTINGS.SHOW_EMAIL_TEMPLATE />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.pages.path,
		element: <SETTINGS.PAGES />,
		exact: true,
	},
	{
		path: '/settings/add-page',
		element: <SETTINGS.ADD_PAGE />,
		exact: true,
	},
	{
		path: 'settings/page/:id',
		element: <SETTINGS.SHOW_PAGES />,
		exact: true,
	},
	{
		path: '/settings/users/:id',
		element: <SETTINGS.SHOWUSER />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.role.path,
		element: <SETTINGS.ROLE />,
		exact: true,
	},
	{
		path: 'settings/roles-update',
		element: <SETTINGS.ROLE_Manage />,
		exact: true,
	},
	{
		path: 'settings/roles-add',
		element: <SETTINGS.ROLE_Manage />,
		exact: true,
	},
	// {
	// 	path: settings.singlePages.subMenu.zones.path,
	// 	element: <SETTINGS.ZONE />,
	// 	exact: true,
	// },
	{
		path: settings.singlePages.subMenu.countries.path,
		element: <SETTINGS.COUNTRY />,
		exact: true,
	},

	{
		path: settings.singlePages.subMenu.states.path,
		element: <SETTINGS.STATE />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.locations.path,
		element: <SETTINGS.LOCATION />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.acutionYard.path,
		element: <SETTINGS.ACUTION_YARD />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.downloadFile.path,
		element: <SETTINGS.DOWNLOADFILES />,
		exact: true,
	},
	{
		path: '/settings/download-files/:id',
		element: <SETTINGS.SHOWDF />,
		exact: true,
	},
	{
		path: 'settings/acution-yard/:id',
		element: <SETTINGS.ACUTION_YARD_SHOW />,
		exact: true,
	},
	{
		path: 'settings/user-permission',
		element: <SETTINGS.USER_ROLE />,
		exact: true,
	},
	{
		path: 'settings/banners',
		element: <SETTINGS.BANNERS />,
		exact: true,
	},
	{
		path: 'settings/about-banners',
		element: <SETTINGS.ABOUTBANNERS />,
		exact: true,
	},
	{
		path: 'settings/contact-banners',
		element: <SETTINGS.CONTACTBANNERS />,
		exact: true,
	},
	{
		path: 'settings/download-banners',
		element: <SETTINGS.DOWNLOADBANNERS />,
		exact: true,
	},
	{
		path: 'settings/career-banners',
		element: <SETTINGS.CAREERBANNERS />,
		exact: true,
	},
	{
		path: 'settings/blog-banners',
		element: <SETTINGS.BLOGBANNERS />,
		exact: true,
	},
	{
		path: 'settings/popup-banners',
		element: <SETTINGS.POPUPBANNER />,
		exact: true,
	},
	{
		path: 'settings/catalogs',
		element: <SETTINGS.CATALOGS />,
		exact: true,
	},
	{
		path: 'settings/generatecatalogs',
		element: <SETTINGS.GENERATECATALOGS />,
		exact: true,
	},
	{
		path: 'notification-all',
		element: <NOTIFICATIONS.NOTIFICATION />,
		exact: true,
	},
	{
		path: accountings.singlePages.path,
		element: <ACCOUNTINGS.ACCOUNTING />,
		exact: true,
	},
	{
		path: '/sold-accounting-vehicle',
		element: <ACCOUNTINGS.VEHICLESOLD />,
		exact: true,
	},
	{
		path: '/sold-latest-vehicle',
		element: <ACCOUNTINGS.LATESTSOLD />,
		exact: true,
	},
	{
		path: invoice.singlePages.path,
		element: <INVOICES.INVOICE />,
		exact: true,
	},
	{
		path: '/invoices/:id',
		element: <INVOICES.SHOWINVOICE />,
		exact: true,
	},
	{
		path: bill.singlePages.path,
		element: <BILLS.BILL />,
		exact: true,
	},
	{
		path: '/bills/:id',
		element: <BILLS.SHOWBILL />,
		exact: true,
	},

	{
		path: productService.singlePages.path,
		element: <PRODUCTSERVICE.SERVICE />,
		exact: true,
	},
	{
		path: faqs.singlePages.path,
		element: <FAQS.FAQ />,
		exact: true,
	},
	{
		path: '/faqs/:id',
		element: <FAQS.SHOW_FAQS />,
		exact: true,
	},
	{
		path: jobs.singlePages.path,
		element: <JOBS.JOBS />,
		exact: true,
	},
	{
		path: '/jobs/:id',
		element: <JOBS.SHOW_JOBS />,
		exact: true,
	},
	{
		path: customer_queries.singlePages.path,
		element: <CUSTOMER_QUERIES.CUSTOMER_QUERIE />,
		exact: true,
	},
	{
		path: '/customers-queries/:id',
		element: <CUSTOMER_QUERIES.SHOW_QUERIE />,
		exact: true,
	},
	{
		path: auctions.singlePages.path,
		element: <ACUTIONS.ACUTION />,
		exact: true,
	},
	{
		path: bidAuction.singlePages.path,
		element: <BIDACUTION.BIDACUTION />,
		exact: true,
	},
	{
		path: 'auctions/:id',
		element: <ACUTIONS.SHOWACUTION />,
		exact: true,
	},
	{
		path: 'running-auctions/:id',
		element: <ACUTIONS.RUNNINGACUTION />,
		exact: true,
	},
	{
		path: profiles.singlePages.path,
		element: <PROFILES.PROFILE />,
		exact: true,
	},

	{
		path: layoutMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
		exact: true,
	},
	{
		path: 'blogs',
		element: <SETTINGS.BLOG />,
		exact: true,
	},
	{
		path: 'blogs/:id',
		element: <SETTINGS.SHOWBLOG />,
		exact: true,
	},
	{
		path: 'blogs/add-blog',
		element: <SETTINGS.ADDBLOG />,
		exact: true,
	},
	{
		path: 'contents',
		element: <SETTINGS.CONTENT />,
		exact: true,
	},
	{
		path: 'contents/add-content',
		element: <SETTINGS.ADDCONTENT />,
		exact: true,
	},
	{
		path: 'contents/:id',
		element: <SETTINGS.SHOWCONTENT />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
